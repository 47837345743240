import angular from 'angular';
import './pp/app';
const singleAppTemplate = require('./mgmt/applications/singleapp.tpl.html');
const appListTemplate = require('./mgmt/applications/applist.tpl.html');
const tkrCustomModule = angular.module('tkr.custom');
tkrCustomModule.decorator('customizationConfig', CustomizationConfigDecorator);
CustomizationConfigDecorator.$inject = ['$delegate'];
function CustomizationConfigDecorator($delegate) {
    const cc = $delegate;
    cc.prcListDetailTemplate = require('./mgmt/prc/prc-list.tpl.html');
    cc.prcEditTemplate = require('./mgmt/prc/prc-edit.tpl.html');
    cc.getAppListTemplate = (appPeriod) => {
        return appListTemplate;
    };
    cc.getSingleAppTemplate = (appPeriod) => {
        return singleAppTemplate;
    };
    return cc;
}
