import angular from 'angular';
import ngMessages from 'angular-messages';
import moment from 'moment';
import TkrSpaCommon from '../../../common/tkr-spa';
import { TkrPersonService } from '../../../common/tkr-commonservices';
import { IbanService, IbanValidator } from '../../../common/ma-iban';
import { SmartFloat, SmartInteger } from '../../../common/ma-smartfloat-1.0';
import TkrPpCommon from '../../../pp/common/0-pp-common-module';
import TkrPpResources from '../../../pp/prcportal-resource-modules';
const subsidy_application_app = angular.module('ng-tkr-app-mkl', [
    TkrSpaCommon,
    ngMessages,
    TkrPersonService,
    IbanValidator,
    IbanService,
    SmartFloat,
    SmartInteger,
    TkrPpResources,
    TkrPpCommon,
]);
export default subsidy_application_app.name;
subsidy_application_app.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {
        $urlRouterProvider.otherwise('/err');
        $stateProvider
            .state('err', {
            url: '/err',
            template: '<p class="h4 text-danger">Sisäinen virhe. Ota yhteyttä helpdesk@matriset.fi.</p>'
        })
            .state('root', {
            url: '',
            template: '',
            controller: 'AppRedirCtrl',
            controllerAs: 'vm'
        })
            .state('lomake', {
            url: '/:applicationId',
            template: require('./app.tpl.html'),
            controller: 'SubsidyApplicationController',
            controllerAs: 'vm',
            resolve: {
                app: ['PortalSubsidyAppService', '$stateParams', function (PortalSubsidyAppService, $stateParams) {
                        return PortalSubsidyAppService.get({ appId: $stateParams.applicationId }).$promise;
                    }],
                appPeriod: ['PortalAppPeriodService', 'app', function (PortalAppPeriodService, app) {
                        return PortalAppPeriodService.get({ periodId: app.applicationPeriodId }).$promise;
                    }]
            }
        })
            .state('jatetty', {
            url: '/:applicationId/jatetty',
            template: require('./app-submitted.tpl.html'),
            controller: 'AppSubmittedController',
            controllerAs: 'vm'
        });
    }]);
subsidy_application_app.controller('SubsidyApplicationController', ['$scope',
    'app',
    'appPeriod',
    'TkrPersonService',
    'IbanService',
    '$state',
    '$stateParams',
    function SubsidyApplicationController($scope, app, appPeriod, TkrPersonService, IbanService, $state, $stateParams) {
        var vm = this;
        vm.vuosi = appPeriod.td.wnum.vuosi;
        vm.edvuosi = vm.vuosi - 1;
        vm.alkuAika = moment(appPeriod.start).local().format('D.M.YYYY HH:mm');
        vm.loppuAika = moment(appPeriod.end).local().format('D.M.YYYY HH:mm');
        vm.personRoles = TkrPersonService.personRoles;
        vm.chairmanRoles = TkrPersonService.chairmanRoles;
        function preProcessApp(data) {
            if (!data.chairman) {
                data.chairman = {};
            }
            if (!data.contactPerson) {
                data.contactPerson = {};
            }
            if (!data.people) {
                data.people = {};
            }
            if (!data.people.jasen1) {
                data.people.jasen1 = {};
            }
            if (!data.people.jasen2) {
                data.people.jasen2 = {};
            }
            if (data.iban) {
                data.iban = IbanService.prettifyIban(data.iban);
            }
            if (!data.td) {
                data.td = {};
            }
            if (!data.td.num) {
                data.td.num = {};
            }
            if (!data.td.wnum) {
                data.td.wnum = {};
            }
        }
        preProcessApp(app);
        vm.app = app;
        $scope.$watch('vm.app.contactPerson.role', function (newValue, oldValue) {
            vm.contactIsChairman = TkrPersonService.isValidChairmanRole(newValue);
        });
        var old_chairman = null;
        $scope.$watch('vm.contactIsChairman', function (newValue, oldValue) {
            if (!newValue) {
                if (old_chairman) {
                    vm.app.chairman = old_chairman;
                }
            }
            else if (newValue && !oldValue) {
                old_chairman = vm.app.chairman;
                vm.app.chairman = vm.app.contactPerson;
            }
        });
        $scope.$watch('vm.app.iban', function (newValue, oldValue) {
            if (newValue) {
                var bic = IbanService.getBicForIban(newValue);
                if (bic && bic !== 'VIRHE') {
                    vm.app.bic = bic;
                }
                else {
                    vm.app.bic = '';
                }
            }
        });
        vm.isChairmanRequired = function () {
            return vm.app ? vm.app.established : true;
        };
        vm.isSeparateChairmanRequired = function () {
            if (!vm.isChairmanRequired()) {
                return false;
            }
            if (!vm.app || !vm.app.contactPerson) {
                return true;
            }
            return TkrPersonService.isValidChairmanRole(vm.app.contactPerson.role) ? false : true;
        };
        $scope.$watchGroup(['vm.app.subsidiedLength', 'vm.app.td.num.Ikm', 'vm.app.td.num.IIkm', 'vm.app.td.num.IIIkm'], function (n) {
            var km = parseFloat(n[0]);
            var I = parseFloat(n[1]);
            var II = parseFloat(n[2]);
            var III = parseFloat(n[3]);
            if (km && I && II && III) {
                var s = I + II + III;
                var ero = Math.abs(km - s);
                if (Math.abs(ero) < 0.00001) {
                    vm.lkVirhe = null;
                }
                else {
                    vm.lkVirhe = "Luokkien summa " + s.toFixed(3) + " km vs kokonaispituus " + km.toFixed(3) + " km";
                }
            }
            $scope.appForm.$setValidity('lksum', vm.lkVirhe ? false : true);
        });
        vm.save = function () {
            if (!vm.isChairmanRequired()) {
                vm.app.chairman = null;
            }
            else if (!vm.isSeparateChairmanRequired()) {
                vm.app.chairman = vm.app.contactPerson;
            }
            vm.app.applicationState = 'SUBMITTED';
            vm.app.$save(function (data) {
                $state.go('jatetty', { applicationId: data.id });
            });
        };
    }]);
subsidy_application_app.controller('AppSubmittedController', ['$scope', '$stateParams', 'TkrConfig',
    function AppSubmittedController($scope, $stateParams, TkrConfig) {
        var vm = this;
        vm.pdfUrl = TkrConfig.aUrl('tiet/hakemukset/' + $stateParams.applicationId + '/Avustushakemus.pdf');
        vm.homeUrl = TkrConfig.aUrl('tiet');
    }]);
subsidy_application_app.directive('mklEdit', EditorDirective);
EditorDirective.$inject = ['$compile'];
function EditorDirective($compile) {
    var buildTemplate = function (scope, attrs) {
        if (!attrs.inputLabel) {
            attrs.inputLabel = scope.inputId;
        }
        var htmlInputType = 'text';
        var inputExtra = '';
        var addonOpen = '';
        var addonClose = '';
        var mSize = attrs.mSize ? attrs.mSize : '';
        if (attrs.mType == 'integer') {
            inputExtra += 'smart-integer="true"';
        }
        else if (attrs.mType == 'number') {
            inputExtra += 'smart-float="true"';
        }
        else if (attrs.mType == 'email') {
            htmlInputType = 'email';
        }
        if (scope.inputAddon) {
            addonOpen = '<div class="input-group">';
            addonClose = '<span class="input-group-addon">' + scope.inputAddon + '</span></div>';
        }
        var hlp = attrs.mHelp ? '<p class="help-block">' + attrs.mHelp + '</p' : '';
        var tpl = '' +
            '<div class="form-group" ng-class="{ \'has-error\' : appForm.INPUTID.$invalid }"> ' +
            '<label for="INPUTID" class="col-sm-3 control-label">INPUTLABEL <span ng-if="required">*</span></label>' +
            '<div class="col-sm-6">' +
            addonOpen +
            '<input type="' + htmlInputType + '" class="form-control M_SIZE" id="INPUTID" name="INPUTID" placeholder="INPUTLABEL" ng-model="ngModel" ' + inputExtra + ' ng-required="required">' +
            addonClose +
            '<div ng-messages="appForm.INPUTID.$error">' +
            '<p ng-message="required" class="help-block bg-danger">Pakollinen tieto</p>' +
            '<p ng-message="integer" class="help-block bg-danger">Syötä kokonaisluku</p>' +
            '<p ng-message="float" class="help-block bg-danger">Syötä desimaaliluku</p>' +
            '<p ng-message="email" class="help-block bg-danger">Syötä sähköpostiosoite</p>' +
            '</div>' +
            hlp +
            '</div>' +
            '</div>';
        var result = tpl.replace(/INPUTID/g, scope.inputId).replace(/INPUTLABEL/g, scope.inputLabel).replace(/M_SIZE/g, mSize);
        return result;
    };
    var linker = function linker(scope, element, attrs, ctrls) {
        scope.appForm = ctrls[0];
        if (attrs.ngModel !== undefined) {
            scope.inputId = attrs.ngModel.replace(/\W/g, '');
        }
        else {
            scope.inputId = 'editor' + scope.$id;
        }
        scope.inputLabel = attrs.inputLabel;
        scope.inputAddon = attrs.inputAddon;
        scope.required = attrs.required;
        element.html(buildTemplate(scope, attrs)).show();
        $compile(element.contents())(scope);
    };
    return {
        restrict: 'AE',
        replace: false,
        require: ['^form'],
        transclude: true,
        scope: {
            ngModel: '=',
            mSize: '='
        },
        link: linker
    };
}
